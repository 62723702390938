import { Container, Text, Flex, ThemeUIStyleObject } from 'theme-ui';
import ProductAddToCartButton from '~/components/Product/AddToCartButton';
// @ts-ignore - Not types for useTranslate
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { useState, useRef, useCallback, Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';
import ProductOutOfStock from '~/components/Product/OutOfStock';
import LockIcon from '~/assets/images/icons/lock.svg';
import SpinnerBar from '~/components/Spinner/loader';
import BundleProduct from './BundleProduct';
import { usePDPWrapperValues } from '~/components/Generic/PDPWrapper';
import CTAButtonIcon from '~/components/Generic/CTAButtonIcon';
import { ProductMap } from '~/types';
import { isBundle } from '.';

type Part = {
  sku: string;
  quantity: number;
};

type Props = {
  collectionName: string;
  bundle: Queries.BundlePageFragment | Queries.KitPageFragmentFragment;
  forwardSx?: ThemeUIStyleObject;
  setBundleAvailable: Dispatch<SetStateAction<boolean>>;
  bundleAvailable: boolean;
  productsMap: ProductMap;
  setProductsMap: Dispatch<SetStateAction<ProductMap>>;
  comingSoonNotify: MutableRefObject<Queries.NotificationFragmentFragment | null>;
  OOSNotify: MutableRefObject<Queries.NotificationFragmentFragment | null>;
  freeShipping?: MutableRefObject<Queries.NotificationFragmentFragment | null>;
};

const BundleAddToCart = ({
  collectionName,
  bundle,
  forwardSx,
  setBundleAvailable,
  bundleAvailable,
  productsMap = {},
  setProductsMap,
  comingSoonNotify,
  OOSNotify,
  freeShipping,
}: Props) => {
  const translate = useTranslate();
  const addToCartRef = useRef<HTMLDivElement>(null);
  const {
    addToCart,
    cart: { lineItems },
  } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isMembersOnlyVariant, setIsMembersOnlyVariant] = useState(false);
  const [currentProductVariantSku, setCurrentProductVariantSku] = useState(sortSkusAndStringify(productsMap));

  const { isMembersOnlyProduct, triggerGatedModal, isLoggedIn, isFetching } = usePDPWrapperValues();
  const { products } = bundle;
  const masterSku = isBundle(bundle) ? bundle?.masterSku : null;
  const [parts, setParts] = useState<Part[]>([]);
  const li = lineItems?.find((li) => li.variant.sku === masterSku);
  const renderOutOfStock = !bundleAvailable || bundle.comingSoon || bundle.discontinued;

  function sortSkusAndStringify(mapOfProducts: ProductMap) {
    return Object.keys(mapOfProducts)
      .map((key) => mapOfProducts[key].sku)
      .sort()
      .join('|');
  }
  function createBundleParts(productsMap: ProductMap): Part[] {
    return Object.keys(productsMap).map((key) => {
      return {
        sku: productsMap[key].sku,
        quantity: 1,
      };
    });
  }

  useEffect(() => {
    if (isBundle(bundle)) {
      if (Object.keys(productsMap).length) {
        setParts(createBundleParts(productsMap));
      }
    } else {
      setCurrentProductVariantSku(sortSkusAndStringify(productsMap));
    }
  }, [productsMap, setParts, bundle]);

  const selectNewProduct = useCallback(
    (sku: string, productSlug: string, itemAvailability: boolean, isMembersOnlyVariant: boolean, index: number) => {
      const slug = `${productSlug}--${index}`;

      setProductsMap((prev) => ({
        ...prev,
        [slug]: {
          sku: sku,
          isAvailable: itemAvailability,
          isMembersOnly: isMembersOnlyVariant,
        },
      }));

      const isAvailable = Object.keys(productsMap).every((key) => productsMap[key].isAvailable);
      const isGated = Object.keys(productsMap).some((key) => productsMap[key].isMembersOnly);
      // Set if product is available based on updated data from individual products!
      setBundleAvailable(isAvailable);
      setIsMembersOnlyVariant(isGated);
    },
    [productsMap, setProductsMap, setBundleAvailable]
  );

  useEffect(() => {
    const isAvailable = Object.keys(productsMap).every((key) => productsMap[key].isAvailable);
    // Set up productAvailability based on comingSoon, individual product stock and/or kit discontinued.
    setBundleAvailable(isAvailable && !bundle?.comingSoon && !bundle?.discontinued);
  }, [bundle?.comingSoon, bundle?.discontinued, setBundleAvailable, productsMap]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();

    if (!bundleAvailable) return;
    // This controls quantity updates in cart
    const itemAddedToCart = new CustomEvent('itemAddedToCart', {
      bubbles: true,
      detail: { quantity: li?.quantity || 0, id: li?.id },
    });
    setIsLoading(true);
    setApiError(null);
    try {
      if (isBundle(bundle) && masterSku) {
        await addToCart({ sku: masterSku, quantity: 1, options: { parts } });
      } else {
        await addToCart({ sku: currentProductVariantSku, quantity: 1 });
      }
      addToCartRef?.current?.dispatchEvent(itemAddedToCart);
    } catch (error) {
      setApiError(translate('error.api.default'));
    }

    setIsLoading(false);
  };

  return (
    <Container variant="fullwidth">
      <div
        ref={addToCartRef}
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          gap: 'var(--vertical-spacing)',
          ...forwardSx,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 'var(--vertical-spacing)',
          }}
        >
          {products?.map((product, index) => {
            if (!product) return null;
            return (
              <form key={`${product.name}-${index}`}>
                <BundleProduct
                  forwardSx={index === products.length - 1 && bundle.model ? { mb: '0' } : {}}
                  product={product}
                  index={index}
                  selectNewProduct={selectNewProduct}
                  collectionName={collectionName}
                  kitSlug={bundle.slug ?? ''}
                  productId={`${product.slug}--${index}`}
                />
              </form>
            );
          })}
        </Flex>

        <Container variant="fullwidth">
          {(isMembersOnlyProduct || isMembersOnlyVariant) && !isLoggedIn ? (
            <CTAButtonIcon
              baseColor="Black"
              size="Medium"
              variant="Filled"
              forwardSx={{
                mb: '4.8rem',
                width: '100%',
                ':disabled': {
                  backgroundColor: 'cloud',
                },
              }}
              onClick={triggerGatedModal}
              disabled={isFetching}
              icon={!isFetching && <LockIcon />}
            >
              {isFetching ? <SpinnerBar /> : translate('product.members_only')}
            </CTAButtonIcon>
          ) : bundle.comingSoon ? (
            <Text
              sx={{
                textTransform: 'uppercase',
                fontWeight: 700,
                fontSize: '24px',
                color: 'rgba(0,0,0,.3)',
                mb: '.5rem',
              }}
            >
              {translate('product.coming_soon')}
            </Text>
          ) : (
            <form onSubmit={handleSubmit}>
              <ProductAddToCartButton
                discontinued={bundle.discontinued}
                outOfStock={!bundleAvailable}
                comingSoon={bundle.comingSoon}
                disabled={!bundleAvailable || li?.quantity === 3}
                quantityReached={li?.quantity === 3}
                isLoading={isLoading}
                error={apiError}
                freeShipping={freeShipping?.current}
              />
            </form>
          )}
        </Container>
      </div>

      {renderOutOfStock && (
        <ProductOutOfStock
          comingSoonNotify={comingSoonNotify}
          OOSNotify={OOSNotify}
          sku={masterSku ?? ''}
          product={bundle}
          comingSoon={bundle.comingSoon ?? false}
          outOfStock={!bundleAvailable}
          discontinued={bundle.discontinued ?? false}
        />
      )}
    </Container>
  );
};

export default BundleAddToCart;
