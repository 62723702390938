import { Box, Text, Flex, ThemeUIStyleObject, Grid } from 'theme-ui';
import { useEffect, useState } from 'react';
import useProductVariants from '~/hooks/components/use-product-variants';
import filterDuplicates from '~/utils/filter-duplicates';
import ProductOptionSelector from '~/components/Product/OptionSelector';
import ImgLoader from '~/components/Generic/ImgLoader';
import SizeChart from '~/components/Product/SizeChart';
import ShippingNote from '~/components/Generic/ShippingNote';
import { getOptionsBySlug } from '~/hooks/utils/product-helpers';
import SizeChartButton from '~/components/Generic/PDPWrapper/SizeChartButton';
import useProductAvailability, { VariantWithStock } from '~/hooks/components/use-product-availability';

type Props = {
  product: Queries.ProductDetailsFragmentFragment;
  collectionName: string;
  selectNewProduct: (
    sku: string,
    productSlug: string,
    itemAvailability: boolean,
    isMembersOnlyVariant: boolean,
    index: number
  ) => void;
  kitSlug: string;
  forwardSx?: ThemeUIStyleObject;
  index: number;
  productId: string;
};

const BundleProduct = ({
  product,
  collectionName,
  selectNewProduct,
  kitSlug,
  forwardSx = {},
  index,
  productId,
}: Props) => {
  const { productWithStock } = useProductAvailability(product);
  const variants: VariantWithStock[] = productWithStock?.variants ?? [];
  const { currentVariant, selectVariant } = useProductVariants(product, 'details', collectionName, kitSlug);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const [activeVariantId, setActiveVariantId] = useState(currentVariant?.id);
  const selectedVariant = variants?.find((v) => v?.id === activeVariantId);
  const [selectedOptions, setSelectedOptions] = useState(selectedVariant?.optionValues);

  // Option values available across variants
  const optionValues = filterDuplicates(
    product?.variants?.reduce((acc: (Queries.OptionValuesFragmentFragment | null)[], variant) => {
      if (variant?.optionValues && acc) return [...acc, ...variant?.optionValues];
      return acc;
    }, []),
    'slug'
  );
  const sizes = getOptionsBySlug('size', optionValues);
  const colors = getOptionsBySlug('color', optionValues);

  const isAvailable = selectedVariant?.inStock;
  const selectedColor = selectedVariant?.optionValues?.find((ov) => ov?.option_type?.[0]?.slug === 'color');
  const selectedSize = selectedVariant?.optionValues?.find((ov) => ov?.option_type?.[0]?.slug === 'size');

  const itemAvailability = !!isAvailable && !selectedVariant?.comingSoon && !selectedVariant?.discontinued;

  const getSoldOutStatusText = (
    generalAvailability: boolean | undefined,
    selectedSize: string | undefined | null,
    selectedColor: string | undefined | null,
    comingSoon?: boolean | null,
    discontinued?: boolean | null
  ) => {
    const sizeColor = selectedSize && selectedColor ? `${selectedSize} ${selectedColor}` : null;
    if (!sizeColor) return 'Sorry, this item is sold out';
    if (discontinued) return `Sorry the ${sizeColor} option is discontinued`;
    if (comingSoon) return `The ${sizeColor} option is coming soon`;
    if (generalAvailability === false) return `Sorry, the ${sizeColor} is currently sold out`;
    return null;
  };

  const itemSoldOutStatus = getSoldOutStatusText(
    isAvailable,
    selectedSize?.presentation,
    selectedColor?.presentation,
    selectedVariant?.comingSoon,
    selectedVariant?.discontinued
  );

  useEffect(() => {
    if (!product.slug) return;
    setActiveVariantId(currentVariant?.id);
    setSelectedOptions(currentVariant?.optionValues);
    selectNewProduct(
      currentVariant?.sku ?? '',
      product.slug,
      itemAvailability,
      currentVariant?.optionValues?.some(
        (value: Queries.OptionValuesFragmentFragment | null) => value && value.isMembersOnly
      ) ?? false,
      index
    );
    // adding selectNewProduct to dependencies will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant, itemAvailability, index, product.slug]);

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'cloud',
        ...forwardSx,
      }}
    >
      <Flex sx={{ justifyContent: 'space-between', width: '100%', alignItems: 'start', gap: '1.8rem' }}>
        <Grid sx={{ flex: 1, gap: 'var(--vertical-spacing)' }}>
          {product.longName && (
            <Text
              as="p"
              variant="PDPSubTitle"
              sx={{ textTransform: 'capitalize' }}
              dangerouslySetInnerHTML={{ __html: product.longName.toLocaleLowerCase() }}
            />
          )}

          {optionValues && colors ? (
            <ProductOptionSelector
              type="color"
              options={colors}
              selectedOptions={selectedOptions || []}
              handleSelection={selectVariant}
              isKit
              presentation={selectedColor?.presentation}
              productId={productId}
            />
          ) : null}

          {optionValues && sizes ? (
            <ProductOptionSelector
              type="size"
              options={sizes}
              selectedOptions={selectedOptions || []}
              handleSelection={selectVariant}
              presentation={selectedSize?.presentation}
              isKit
              productId={productId}
            />
          ) : null}
        </Grid>

        <Flex
          sx={{
            height: '100%',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: ['1.2rem', '0.8rem'],
            position: 'relative',
          }}
        >
          {selectedVariant?.mainImage ? (
            <ImgLoader forwardSx={{ height: '9rem', width: '9rem' }} image={selectedVariant.mainImage} />
          ) : null}

          <SizeChartButton setShowSizeChart={setShowSizeChart} isKit />
        </Flex>
      </Flex>

      <SizeChart isOpen={showSizeChart} onDismiss={() => setShowSizeChart(false)} product={product} />

      <Grid
        sx={{
          mb: 'var(--vertical-spacing)',
          mt: itemSoldOutStatus || selectedVariant?.optionalShippingNote ? 'var(--vertical-spacing)' : 0,
          gap: 'var(--vertical-spacing)',
        }}
      >
        {itemSoldOutStatus && (
          <Text
            as="p"
            sx={{
              fontSize: ['1.2rem', '1.4rem'],
              lineHeight: 1,
              fontWeight: 500,
              letterSpacing: '-0.01em',
            }}
          >
            {itemSoldOutStatus}
          </Text>
        )}

        {selectedVariant?.optionalShippingNote && <ShippingNote note={selectedVariant.optionalShippingNote} />}
      </Grid>
    </Box>
  );
};

export default BundleProduct;
